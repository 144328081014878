import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'

const Footer = () => (
  <footer>
    <Row>
      <Col xs={12} className='justify-content-center text-center mb-4'>
        <p style={{fontSize: '0.85rem'}}>&copy;Kapuyuak2020</p>
      </Col>
    </Row>
  </footer>
)

export default Footer