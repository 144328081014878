import React from 'react'
import Container from 'react-bootstrap/Container'
import { 
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import Header from '../Header'
import Home from '../Home'
import { Detail } from '../Items/'
import Footer from '../Footer'

import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => (
  <Router>
    <Container fluid style={{maxWidth:'1200px'}}>
      <Header />
      <MyRoute />
      <Footer />
    </Container>
  </Router>
)

const MyRoute = () => (
  <Switch>
    <Route path="/items/:itemId" render={(props) => <Detail {...props} />} />
    <Route path="/" exact render={(props) => <Home {...props} />} />
  </Switch>
)

export default App