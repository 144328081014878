import React from 'react'
import {
  Row,
  Col,  
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import logo from '../images/kapuyuak200.png'
const Header = () => (
  <header>
    <Row className='justify-content-md-center'>
      <Col xs={12} md={4} style={{paddingTop: '5rem', paddingBottom: '5rem'}}>
        <Link to='/'>
          <img src={logo} className='m-auto d-block' alt='site-logo' />
        </Link>
      </Col>
    </Row>
  </header>
)

export default Header