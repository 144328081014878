import React from 'react'
import { List } from '../Items'


const Home = () => (
  <div>
    <List />
  </div>
)

export default Home