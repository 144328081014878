import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

import waImg from '../../src/images/wa.png'

/**************************************** HOME *******************************/
class Detail extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      item: {},
      isGettingItem: false,
      activeImgIdx: -1,
    }
  }

  componentDidMount () {
    this.getItem();
  }

  getItem = () => {
    if (this.state.isGettingItem) return

    const {itemId} = this.props.match.params
    
    this.setState({isGettingItems: true}, () => {
      setTimeout(()=> {
      let urlString = process.env.REACT_APP_API_URL + '/items/' + itemId
      fetch(urlString, {mode: process.env.REACT_APP_CORS_MODE})
        .then(res => res.json())
        .then(result => { 
          const item = result.values
          this.setState({ 
            item: item,
            activeImgIdx: (item && item.ItemImages && item.ItemImages[0]) ? 0 : -1, 
            isGettingItem: false,
          })
        })
        .catch(e => {
          this.setState({isGettingItems: false})
        })
      }, 1000)
    })
  }

  changeActiveImage = (i) => {
    this.setState({activeImgIdx: i})
  }

  openWa = (e, item) => {
    let baseUrl = 'https://wa.me/'
    baseUrl = baseUrl.concat('6281378096999')
    baseUrl = baseUrl.concat('?text=')

    let text = window.location.protocol
    text = text.concat('//')
    text = text.concat(window.location.host)
    text = text.concat('/items/')
    text = text.concat(item.id)
    text = encodeURI(text)
    
    window.open(baseUrl.concat(text), '_blank')
  }

  render () {
    const {item, isGettingItem, activeImgIdx} = this.state
    
    let views = []
    
    if (item && item.ItemImages) {
      for (let i = 0 ; i < item.ItemImages.length ; i++) {
        views.push( 
          <Col xs={4} 
            className='p-1 d-flex justify-content-center overflow-hidden'
            key={i}
          >
            <Image 
              key={i} 
              src={process.env.REACT_APP_URL+'/'+item.ItemImages[i].url}
              alt={'image_'+i}
              className='mx-auto w-100'
              onClick={() => this.changeActiveImage(i)}
            />
          </Col>
        )
      }
    }

    return (
      <React.Fragment>
        <style>
          {`
          .btn-link {
            color: black;  
          }
          .btn-link:hover {
            color: black;
            background: none;
            text-decoration: none;  
          }
          `}
        </style>
        <Row>
          <Col xs={12} md={6}>
            {activeImgIdx > -1 && 
              <Row>
                <Col className='d-flex justify-content-end'>
                  <Image 
                    className='w-100'
                    src={process.env.REACT_APP_URL+'/'+item.ItemImages[activeImgIdx].url} alt='main images' 
                  />
                </Col>
              </Row>
            }   
            <Row>
              <Col className='d-flex justify-content-start p-4'>
                <Row>
                  {views}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={6}>
                <h4 style={{fontSize: '1rem'}}>{item.name}</h4>
                <p style={{fontSize: '1rem'}}>{item.price}</p>
              </Col>
              <Col xs={6}>
                <Button 
                  variant='link'
                  size='sm' 
                  className='mx-0 px-0'
                  onClick={e => this.openWa(e, item)}
                  style={{fontSize: '0.9rem'}}
                >
                  <span>order&nbsp;&nbsp;&nbsp;</span>
                  <img src={waImg} style={{width: '1.5rem', height: 'auto'}} alt='Order' />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div>
          {isGettingItem}
        </div>
      </React.Fragment>
    )
  }

}

export default Detail