import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ContentLoader from 'react-content-loader'
import NumberFormat from 'react-number-format'
import debounce from 'lodash.debounce'

import Listgroup from './list-group'

import waImg from '../../src/images/wa.png'

/**************************************** HOME *******************************/
class List extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      limit: 9,
      rows: [],
      pinnedRows: [],
      isGettingItems: false,
      isGettingStickyItems: false,
      showModal: false,
      modalIdx: -1,
      modalMode: 'regular',
      noMoreItem: false,
      isCompact: false,
    }

    window.onscroll = debounce(() => {    
      if (document.documentElement.scrollTop + window.innerHeight 
          >=
          document.documentElement.offsetHeight
          && !this.state.noMoreItem) {
          this.getItems()
      }
    })
  }

  componentDidMount () {
    this.setState({isCompact: window.innerWidth < 768})
    window.addEventListener('resize', this.windowResized)
    this.getSticky()
    this.getItems()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.windowResized)
  }

  windowResized = () => {
    this.setState({isCompact: window.innerWidth < 768})
  }

  getSticky = () => {
    if (this.state.isGettingStickyItems) return

    this.setState({isGettingStickyItems: true}, () => {
      setTimeout(()=> {
      let urlString = process.env.REACT_APP_API_URL + '/items/'
      urlString = urlString.concat('?av=1&pn=1')
      urlString = urlString.concat('&p='+0)
      urlString = urlString.concat('&l='+9)

      fetch(urlString, {mode: process.env.REACT_APP_CORS_MODE})
        .then(res => res.json())
        .then(result => { 
          this.setState( state => {
            return {
              pinnedRows:result.values,
              isGettingStickyItems: false,
            }
          })
        })
        .catch(e => {
          this.setState({isGettingStickyItems: false})
        })
      }, 500)
    })
  }

  getItems = () => {
    if (this.state.isGettingItems) return

    const {page,limit} = this.state

    this.setState({isGettingItems: true}, () => {
      setTimeout(()=> {
      let urlString = process.env.REACT_APP_API_URL + '/items/'
      urlString = urlString.concat('?av=1&pn=2')
      urlString = urlString.concat('&p='+page)
      urlString = urlString.concat('&l='+limit)

      fetch(urlString, {mode: process.env.REACT_APP_CORS_MODE})
        .then(res => res.json())
        .then(result => { 
          this.setState( state => {
            return {
              noMoreItem: result.values.length === 0,
              rows: [...state.rows, ...result.values],
              page: (result.values.length > 0) ? state.page + 1 : state.page,
              isGettingItems: false,
            }
          })
        })
        .catch(e => {
          this.setState({isGettingItems: false})
        })
      }, 1000)
    })
  }

  showDetailImages = (mode, idx) => {
    let item = this.state.rows[idx]
    if (mode === 'pinned') {
      item = this.state.pinnedRows[idx]
    }
    
    if (item) {
      this.setState({showModal: true, modalMode: mode, modalIdx: idx})
    }
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  render () {
    const {rows, pinnedRows, modalMode, modalIdx, isGettingItems, showModal, isCompact} = this.state
    
    let views = []
    let i = 0
    let j = rows.length 
    let chunk = (isCompact)? 2 : 3

    for (i ; i < j ; i += chunk) {
      let subArray = rows.slice(i, i+chunk)
      views.push( 
        <Listgroup 
          key={i} 
          items={subArray} 
          indexStart={i} 
          mode='regular'
          showDetailImages={this.showDetailImages}
        /> 
      )
    }

    let pinnedViews = []
    i = 0
    j = pinnedRows.length 

    for (i ; i < j ; i += chunk) {
      let subArray = pinnedRows.slice(i, i+chunk)
      pinnedViews.push( 
        <Listgroup 
          key={i} 
          items={subArray} 
          indexStart={i} 
          mode='pinned'
          showDetailImages={this.showDetailImages}
        /> 
      )
    }

    return (
      <Container fluid style={{marginBottom:'100px'}}>
        <Modal show={showModal} onHide={this.closeModal} animation={true}>
          <ModalImages 
            item={modalMode === 'pinned' ? pinnedRows[modalIdx] : rows[modalIdx]} 
            handleClose={this.closeModal}
          />
        </Modal>
        {pinnedViews}
        {views}
        <div>
          {isGettingItems && <Loader />}
        </div>
      </Container>
    )
  }

}


/************************************ LOADER *********************************/
const Loader = () => {
  return (
    <React.Fragment>
      <Row>
        <LoaderCol />
        <LoaderCol />
        <LoaderCol upperClassName='d-none d-md-block'/>
      </Row>
      <Row>
        <LoaderCol />
        <LoaderCol />
        <LoaderCol upperClassName='d-none d-md-block'/>
      </Row>
    </React.Fragment>
  )
}

const LoaderCol = ({upperClassName}) => (
  <Col xs={12} sm={6} md={4} className={upperClassName + ' p-1'}>
    <div className='position-relative w-100' style={{paddingTop: '100%'}}>
    <div 
        className='position-absolute'
        style={{top: '0', bottom: '0', right: '0', left: '0', overflow: 'hidden'}}
      >
        <ContentLoader className='w-100 h-100'>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
    </div>
  </Col>
)


/******************************** MODAL IMAGES *******************************/
const ModalImages = ({item, handleClose}) => {

  const [activeIdx, setActiveIdx] = useState(0);

  const openWa = (e, item) => {
    let baseUrl = 'https://wa.me/'
    baseUrl = baseUrl.concat('6281378096999')
    baseUrl = baseUrl.concat('?text=')

    let text = window.location.protocol
    text = text.concat('//')
    text = text.concat(window.location.host)
    text = text.concat('/items/')
    text = text.concat(item.id)
    text = encodeURI(text)
    
    window.open(baseUrl.concat(text), '_blank')
  }

  return (
    <React.Fragment>
      <style>
        {`
        .btn-link {
          color: black;  
        }
        .btn-link:hover {
          color: black;
          background: none;
          text-decoration: none;  
        }
        `}
      </style>
      <ModalHeader>
        <h3 className='text-capitalize' style={{fontSize: '1rem'}}>
          {item.name}
          {' '}
          <NumberFormat 
            value={item.price} 
            displayType={'text'} 
            thousandSeparator={true}
            prefix={'IDR '}
          />
        </h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={3} className='p-1 pr-0'>
          {item.ItemImages.map( (img,k) => (
            <img 
              key={k}
              className='w-100 mb-1' 
              src={process.env.REACT_APP_URL +'/'+ img.url} 
              onClick={()=>setActiveIdx(k)}
              alt={item.name}
            /> 
          ))}
          </Col>
          <Col xs={9} className='p-1 pl-0'>
            <img 
              className='w-100' 
              src={process.env.REACT_APP_URL +'/'+ item.ItemImages[activeIdx].url} 
              alt={item.ItemImages[activeIdx].name}
            /> 
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className='d-flex align-items-center'>
            <div>
              <Button 
                variant='outline-dark'
                size='sm'
                onClick={handleClose}
                className='mx-0 mr-1'
                style={{fontSize: '0.9rem'}}
              >
                <span>
                  Back
                </span>
                <img className='d-none' src={waImg} style={{width: '1.5rem', height: 'auto'}} alt='Order' />
              </Button>
              &nbsp;
              <Button 
                variant='link'
                size='sm' 
                className='mx-0 px-0 d-inline-flex align-items-center'
                onClick={e => openWa(e, item)}
                style={{fontSize: '0.9rem'}}
              >
                <span >
                  Order&nbsp;&nbsp;&nbsp;
                </span>
                <img src={waImg} style={{width: '1.5rem', height: 'auto'}} alt='Order' />
              </Button>
            </div>
          </Col>
        </Row>
      </ModalFooter>

    </React.Fragment>
  )
}

export default List