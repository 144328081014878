import React, { useState } from 'react'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import NumberFormat from 'react-number-format'

import waImg from '../../src/images/wa.png'


/******************************* LIST DETAIL *********************************/
const ListDetail = ({price, name, images, itemId, mode, onClick,idx}) => {
  //const [img, setImg] = useState(true)

  /*
  const checkImg = (url) => {
    return new Promise( res => {
      const http = new XMLHttpRequest()
      http.open('HEAD', url, true)

      http.onload = e => {
        res(http.status !== 404)
      }
      http.onerror = e => {throw e}
      
      http.send(null)
    })
  }

  if (images && images[0] && images[0].url) {  
    const url = images[0].url  
    checkImg(process.env.REACT_APP_URL+'/'+url)
    .then(e => {
      if (e) setImg(process.env.REACT_APP_URL+'/'+url)
    })
    .catch( e => {setImg(null)})
  }
  */
  //if (images && images[0] && images[0].url) setImg(process.env.REACT_APP_URL+'/'+images[0].url)

  const openWa = (e, id) => {
    let baseUrl = 'https://wa.me/'
    baseUrl = baseUrl.concat('6281378096999')
    baseUrl = baseUrl.concat('?text=')

    let text = window.location.protocol
    text = text.concat('//')
    text = text.concat(window.location.host)
    text = text.concat('/items/')
    text = text.concat(id)
    text = encodeURI(text)
    
    window.open(baseUrl.concat(text), '_blank')
  }

  /********************************* RETURN **********************************/
  return (
    <Col xs={12} sm={6} md={4} className='p-1'>
      <style>
        {`
        .btn-link {
          color: black;  
        }
        .btn-link:hover {
          color: black;
          background: none;
          text-decoration: none;  
        }
        `}
      </style>
      <div 
        className='position-relative w-100 bg-light item-col' 
        style={{paddingTop: '100%'}}>
        <div 
          className='position-absolute overflow-hidden'
          style={{top: '0', bottom: '0', right: '0', left: '0'}}
        >
          {(images && images[0] && images[0].url) && 
            <div className='h-100 w-100 d-flex justify-content-center'>
              <Image
                className='h-100'
                src={process.env.REACT_APP_URL+'/'+images[0].url}
                onClick={() => onClick(mode, idx)}
              />
            </div>
          }
          <div style={{bottom: '0', right:'0', left: '0'}} className='position-absolute'>
            <div
              style={{opacity:'0.5', height: '100%', top: '0', bottom: '0', left: '0', right: '0'}}
              className='position-absolute bg-light bgItemText'
            />
            <Row className='m-0 py-2'>
              <Col xs={7} className='d-flex align-items-center'>
                <div>
                  <strong className='text-dark mb-0'>
                    <NumberFormat 
                      value={price} 
                      displayType='text' 
                      prefix='IDR ' 
                      thousandSeparator={true} 
                      style={{fontSize: '0.7rem'}}
                    />
                  </strong>
                  <h4 className='text-dark mb-0 mb-md-1 text-capitalize' style={{fontSize: '0.9rem'}}>
                    {name}
                  </h4>
                </div>
              </Col>
              <Col xs={5} className='d-flex p-0 pr-3 justify-content-end align-items-center'>
                <Button 
                  variant='link'
                  size='sm' 
                  className='mx-0 px-0'
                  onClick={e => openWa(e, itemId)}
                  style={{fontSize: '0.9rem'}}
                >
                  <span>order&nbsp;&nbsp;&nbsp;</span>
                  <img src={waImg} style={{width: '1.5rem', height: 'auto'}} alt='Order' />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Col> 
  )
}

export default ListDetail