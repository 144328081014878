import React from 'react'
import Row from 'react-bootstrap/Row'

import ListDetail from './list-detail'


const ListGroup = ({indexStart, mode, items, showDetailImages}) => {

  let group = [] 

  for (let k = 0 ; k < items.length ; k++) {
    var sample = items[k]
    group.push( 
      <ListDetail 
        key={indexStart+k} 
        price={sample.price} 
        name={sample.name} 
        itemId={sample.id} 
        images={sample.ItemImages} 
        onClick={showDetailImages}
        mode={mode}
        idx={indexStart+k}
      /> 
    )
  }

  return <Row>{group}</Row>
}

export default ListGroup